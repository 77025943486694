import {useEffect} from 'react'
import {useAppPayload} from '../use-app-payload'
import {useMatches, type UIMatch} from 'react-router-dom'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
import type {QueryClient} from '@tanstack/react-query'
import {useQueryClient} from '@github-ui/react-query'
import {QueryRouteQueryType, type QueryDepsFn, type QueryRouteQueryConfig} from './data-router-types'
import type {QueryRoute} from './query-route'
import type {QueryOptionsWithKey} from './use-route-query'

type RouteMatches = Array<
  UIMatch<null | {
    route: QueryRoute<
      string,
      string,
      string,
      Record<
        string,
        QueryRouteQueryConfig<string, string, string, string, QueryDepsFn<string>, string, QueryRouteQueryType>
      >
    >
    queries: Record<string, {type: QueryRouteQueryType; queryConfig: QueryOptionsWithKey}>
  }>
>

// This exists to satisfy the eslint warning `@eslint-react/web-api/no-leaked-event-listener`
// which is triggered by it not knowing that it's a constant string literal when referenced
// as an imported thing.
const SOFT_NAV_INITIAL = SOFT_NAV_STATE.INITIAL

export function PublishPayload() {
  const appPayload = useAppPayload()

  // react-router doesn't give strong types for this, so we can either cast or do a bunch of validation when we access it
  const matches = useMatches() as RouteMatches
  const queryClient = useQueryClient()

  // Fires any time the `matches` changes, such as a soft-nav.
  // Will fire on initial hard-nav too but then the ReactStaffbarElement component
  // might not be ready, yet, to listen to the event.
  useEffect(() => {
    const payload = aggregateMatchData(matches, queryClient)
    document.dispatchEvent(new CustomEvent('soft-nav:payload', {detail: {payload, appPayload}}))
  }, [matches, appPayload, queryClient])

  // Exists for the purpose of the first hard-nav, when the ReactStaffbarElement component might not
  // be ready, yet, but when it is we send the first payload.
  useEffect(() => {
    function onInitialSoftNav() {
      const payload = aggregateMatchData(matches, queryClient)
      document.dispatchEvent(new CustomEvent('soft-nav:payload', {detail: {payload, appPayload}}))
    }
    document.addEventListener(SOFT_NAV_INITIAL, onInitialSoftNav)

    return () => {
      document.removeEventListener(SOFT_NAV_INITIAL, onInitialSoftNav)
    }
  }, [matches, appPayload, queryClient])

  return null
}

function aggregateMatchData(matches: RouteMatches, queryClient: QueryClient) {
  // returns a Record<RouteId, Record<QueryName, Data>>
  return Object.fromEntries(
    matches
      .filter(match => !!match.data?.route)
      .map(match => {
        return [
          match.data?.route.id,
          Object.fromEntries(
            Object.entries(match.data?.queries ?? {})
              .filter(([, config]) => config.type === QueryRouteQueryType.Blocking)
              .map(([name, config]) => {
                return [name, queryClient.getQueryData(config.queryConfig.queryKey)] as const
              }),
          ),
        ]
      }),
  )
}

try{ PublishPayload.displayName ||= 'PublishPayload' } catch {}