import {controller, target} from '@github/catalyst'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {createBrowserHistory} from './create-browser-history'
import type {EmbeddedData} from './embedded-data-types'
import {NavigatorClientEntry} from './NavigatorClientEntry'
import {getReactNavigatorApp, type NavigatorAppRegistrationFn} from './navigator-app-registry'
import {getReactDataRouterApp, type DataRouterAppRegistrationFn} from './future/data-router-app-registry'
import {ReactBaseElement} from './ReactBaseElement'
import {routesWithProviders} from './future/RoutesWithProviders'
import {v7_routeProviderFutureFlags, v7_routerFutureFlags} from './react-router-future-flags'
import {hydrate} from '@tanstack/react-query'
import {getQueryClient} from './query-client'
import type {HydrationState} from '@remix-run/router'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'react-app': React.DetailedHTMLProps<React.HTMLAttributes<ReactAppElement>, ReactAppElement>
    }
  }
}

// What is this silliness? Is it react or a web component?!
// It's a web component we use to bootstrap react apps within the monolith.
@controller
export class ReactAppElement extends ReactBaseElement<EmbeddedData> {
  nameAttribute = 'app-name'
  @target declare reactSSRContext: HTMLScriptElement | null

  async getReactNode(embeddedData: EmbeddedData, onError: (error: Error) => void): Promise<JSX.Element> {
    // The component that wraps this React app will know if the app should be rendered with
    // date router (instead of navigator router) and it does this by setting
    // a `data-data-router-enabled="true"` attribute on this `<react-app>` element.
    // Remember we might have two apps called `some-cool-app`, because it started as a navigator app
    // (i.e. jsonRoute) and now also exists a data router app (i.e. queryRoute). At runtime, we might
    // toggle between which to use based on feature flags.
    const isDataRouterEnabled = this.getAttribute('data-data-router-enabled') === 'true'

    if (isDataRouterEnabled) {
      const app = await getReactDataRouterApp(this.name)
      return this.#getDataRouterNode(embeddedData, onError, app.registration)
    }

    const app = await getReactNavigatorApp(this.name)
    return this.#getNavigatorNode(embeddedData, onError, app.registration)
  }

  #getSSRHydrationData() {
    try {
      const hydrateElement = this.reactSSRContext

      if (hydrateElement) {
        return JSON.parse(hydrateElement.innerHTML) as {
          routerContext?: HydrationState
          queryClient?: unknown
        }
      }
    } catch {
      // if parsing fails, continue and let CSR clean it up
    }

    return null
  }

  async #getDataRouterNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: DataRouterAppRegistrationFn,
  ) {
    const hydrationData = this.#getSSRHydrationData()

    /**
     * When we turbonav into the application with ssr
     * we want to clear the old queries so that the new ones
     * can be hydrated properly, otherwise the hydration
     * pass won't have the correct query client.
     */
    if (hydrationData?.queryClient) {
      const queryClient = getQueryClient()
      queryClient.removeQueries({
        queryKey: [this.name],
      })
      hydrate(getQueryClient(), hydrationData.queryClient)
    }

    const {routes} = registration({
      // when we hydrated the queryClient directly, we don't want to add initialData again
      embeddedData: hydrationData?.queryClient ? undefined : embeddedData,
    })

    const router = createBrowserRouter(
      routesWithProviders(routes, {
        appPayload: embeddedData.appPayload,
        ssrError: this.ssrError,
        appName: this.name,
        wasServerRendered: this.hasSSRContent,
        dataRouterEnabled: true,
      }),
      {
        future: v7_routerFutureFlags,
        hydrationData: hydrationData?.routerContext,
      },
    )

    return (
      <>
        {hydrationData ? (
          <script
            data-target="react-app.reactSSRContext"
            type="application/json"
            suppressHydrationWarning
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(hydrationData),
            }}
          />
        ) : null}
        <RouterProvider router={router} future={v7_routeProviderFutureFlags} />
      </>
    )
  }

  async #getNavigatorNode(
    embeddedData: EmbeddedData,
    onError: (error: Error) => void,
    registration: NavigatorAppRegistrationFn,
  ) {
    const {App, routes} = registration()
    const initialPath = this.getAttribute('initial-path') as string

    if (this.isLazy) {
      const request = await fetch(initialPath, {
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'include',
      })
      const {payload} = await request.json()

      embeddedData.payload = payload
    }

    const window = globalThis.window as Window | undefined

    // Initial path is set by ruby. Anchors are not sent to the server.
    // Therefore anchors must be set explicitly by the client.
    const {pathname, search, hash} = new URL(
      `${initialPath}${window?.location.hash ?? ''}`,
      window?.location.href ?? 'https://github.com',
    )

    const history = createBrowserHistory({window})
    const {key, state} = history.location
    const initialLocation = {
      pathname,
      search,
      hash,
      key,
      state,
    }

    return (
      <NavigatorClientEntry
        appName={this.name}
        initialLocation={initialLocation}
        history={history}
        embeddedData={embeddedData}
        routes={routes}
        App={App}
        wasServerRendered={this.hasSSRContent}
        ssrError={this.ssrError}
        onError={onError}
      />
    )
  }

  get isLazy() {
    return this.getAttribute('data-lazy') === 'true'
  }
}
