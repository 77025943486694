import {ScrollRestoration} from 'react-router-dom'
import {installScrollRestoration, useScrollRestoration} from '../use-scroll-restoration'

installScrollRestoration()

export function CombinedScrollRestoration() {
  // This hook restores turbo-scroll-restoration only on initial render because of the useLayoutEffect.
  // This only happens when you navigate from a turbo link to a react link.
  useScrollRestoration()

  // Otherwise, we let react-router handle scroll restoration on soft navigations.
  return <ScrollRestoration />
}

try{ CombinedScrollRestoration.displayName ||= 'CombinedScrollRestoration' } catch {}